import { useState } from "react";
import styles from "./About.module.scss";
import { Fade } from "react-awesome-reveal";

function About() {
    const [showMore, setShowMore] = useState(false);

    const toggleShowMore = () => setShowMore(!showMore);

    return (
        <section className={styles.about}>
            <h2 className="section-title">Acerca de la empresa</h2>
            <Fade triggerOnce={true}>
                <div className={styles["about-container"]}>
                    <div className={styles.description}>
                        <h3>¿Quiénes somos?</h3>
                        {showMore ? (
                            <p>
                                Somos un consultorio y laboratorio dental
                                integrado formado en el año 2008 por el{" "}
                                <strong>Dr. José David Lara Montejo</strong>,
                                quien en sus más de 30 años de experiencia y,
                                percatándose de las necesidades de sus
                                pacientes, decide atenderlas de una manera más
                                especializada, rápida y efectiva, así fundando
                                Solo Prótesis Dental. En Solo Prótesis Dental,
                                nos preocupamos de la salud bucal y la
                                autoestima de nuestros pacientes; es por esta
                                razón que integramos el trabajo de un
                                consultorio y el de un laboratorio, de tal
                                manera que aseguramos la calidad de nuestro
                                trabajo al mismo tiempo que agilizamos los
                                tiempos de espera para nuestros pacientes,
                                logrando realizarlos en el mismo día e inclusive
                                de manera inmediata.
                            </p>
                        ) : (
                            <p>
                                Somos un consultorio y laboratorio integrado que
                                se formó en el año 2008. Desde hace 30 años el{" "}
                                <strong>Dr. José David Lara Montejo</strong>{" "}
                                inició con la empresa, por lo que contamos con
                                una amplia experiencia.
                            </p>
                        )}

                        <button
                            className="more-button"
                            onClick={toggleShowMore}
                        >
                            {showMore ? "Ver menos" : "Ver más"}
                        </button>
                    </div>
                    <span className={styles.image} />
                </div>
            </Fade>
            {showMore ? (
                <ul>
                    <li className={styles.values}>
                        <img src="images/icons/laptop.gif" alt="misión" />
                        <h4>Misión</h4>
                        <p>
                            Nuestra misión es proporcionar un diagnóstico
                            preciso y brindar atención dental de alta calidad a
                            todos los pacientes que requieran tratamiento. Nos
                            esforzamos por ayudar a nuestros pacientes a
                            recuperar su salud bucal de la manera más adecuada
                            posible.
                        </p>
                    </li>
                    <li className={styles.values}>
                        <img src="images/icons/rocket.gif" alt="visión" />
                        <h4>Visión</h4>
                        <p>
                            En el consultorio y laboratorio dental, nos
                            visualizamos liderando la vanguardia en las nuevas
                            técnicas posicionándonos como uno de los mejores
                            consultorios al satisfacer de manera eficaz y
                            eficiente las necesidades de nuestros pacientes.
                        </p>
                    </li>
                    <li className={styles.values}>
                        <img src="images/icons/goal.gif" alt="objetivo" />
                        <h4>Objetivo</h4>
                        <p>
                            Nuestro objetivo principal es rehabilitar a nuestros
                            pacientes, permitiéndoles recuperar su sonrisa sin
                            comprometer la comodidad a la hora de hablar o
                            gesticular. Trabajamos para ofrecer soluciones
                            dentales que no sólo sean efectivas, sino que
                            también brinden una experiencia cómoda y
                            satisfactoria para nuestros pacientes.
                        </p>
                    </li>
                </ul>
            ) : (
                <></>
            )}
        </section>
    );
}

export default About;
