const services = [
    {
        title: "Escáner Dental 3D",
        image: "images/services/escaner.webp",
    },
    {
        title: "Implantes",
        image: "images/services/implante-dental.webp",
    },
    {
        title: "Reparaciones inmediatas 1hr",
        image: "images/services/inmediata.webp",
    },
    {
        title: "Placas estabilizadoras",
        image: "images/services/placas.webp",
    },
    {
        title: "Prótesis removibles",
        image: "images/services/removibles.webp",
    },
    {
        title: "Prótesis fijas",
        image: "images/services/protesis-fija.webp",
    },
    {
        title: "Carillas",
        image: "images/services/carillas.webp",
    },
    {
        title: "Endodoncias",
        image: "images/services/endodoncia.webp",
    },
    {
        title: "Extracciones",
        image: "images/services/extraccion.webp",
    },
    {
        title: "Tratamiento periodontal",
        image: "images/services/periodontal.webp",
    },
    {
        title: "Resinas",
        image: "images/services/resina.webp",
    },
    {
        title: "Incrustaciones",
        image: "images/services/incrustaciones.webp",
    },
    {
        title: "Alineadores dentales",
        image: "images/services/alineadores.webp",
    },
];

export default services;
