import styles from "./Doctor.module.scss";
import { Fade } from "react-awesome-reveal";

const Doctor = () => {
    return (
        <section className={styles.doctor}>
            <Fade triggerOnce={true}>
                <h2 className="section-title">Conoce al Doctor</h2>
                <div className={styles.container}>
                    <h3 className={styles.name}>Dr. José David Lara Montejo</h3>
                    <div className={styles.overview}>
                        <img src="images/doctor.webp" alt="Dr. David Lara" />
                        <ul className={styles.achievements}>
                            <li>
                                Egresado de la Facultad de Odontología de la{" "}
                                <strong>Universidad Autónoma de Yucatán</strong>
                            </li>
                            <li>30 años de experiencia</li>
                            <li>
                                Socio activo del Colegio de Implantología del
                                Sureste
                            </li>
                            <li>
                                Diplomado de implantología y rehabilitación
                                bucal
                            </li>
                            <li>Certificado de Noritake</li>
                            <li>Curso avanzado de prótesis fija y removible</li>
                        </ul>
                    </div>
                </div>
            </Fade>
        </section>
    );
};

export default Doctor;
