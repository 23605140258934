import styles from "./Header.module.scss";
import { Link } from "react-router-dom";
import links from "../../data/links";
import { useMenu } from "../../contexts/MenuContext";

const Header = () => {
    const { menu, setMenu } = useMenu();

    return (
        <header className={styles["header-container"]}>
            <div className={styles["top-header"]}>
                <a href="tel:9999282528">
                    <img src="/images/icons/phone.svg" alt="phone icon" />
                    9992008328
                </a>
            </div>
            <div className={styles.header}>
                <Link to="/" className={styles.logo}>
                    <img src="/images/logo.webp" alt="logo" />
                </Link>
                <nav className={styles.navigation}>
                    <ul className={styles.links}>
                        {links.map((link, index) => (
                            <li key={index}>
                                <Link to={link.path}>{link.name}</Link>
                            </li>
                        ))}
                    </ul>
                </nav>
                <img
                    className={styles["hamburguer-icon"]}
                    src="images/icons/menu.svg"
                    alt="menu icon"
                    onClick={() => setMenu(!menu)}
                />
            </div>
        </header>
    );
};

export default Header;
