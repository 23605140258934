import { createContext, useContext, useState } from "react";

const MenuContext = createContext(undefined);

export const MenuProvider = ({ children }) => {
    const [menu, setMenu] = useState(false);

    return (
        <MenuContext.Provider value={{ menu, setMenu }}>
            {children}
        </MenuContext.Provider>
    );
};

export const useMenu = () => useContext(MenuContext);
