import styles from "./LateralMenu.module.scss";
import { Link } from "react-router-dom";
import links from "../../data/links";
import { useMenu } from "../../contexts/MenuContext";

const LateralMenu = () => {
    const { menu, setMenu } = useMenu();
    const body = document.getElementsByTagName("body")[0];

    body.style.overflow = menu ? "hidden" : "auto";

    return (
        <div className={styles.container}>
            <span
                onClick={() => setMenu(false)}
                className={`${styles.background} ${
                    menu ? styles["show-background"] : styles["hide-background"]
                }`}
            ></span>
            <nav
                className={`
                    ${styles.menu} ${
                    menu ? styles["show-menu"] : styles["hide-menu"]
                }`}
            >
                <ul className={styles.links}>
                    {links.map((link, index) => (
                        <li key={index}>
                            <Link to={link.path} onClick={() => setMenu(false)}>
                                {link.name}
                            </Link>
                        </li>
                    ))}
                </ul>
                <img
                    src="images/icons/close.svg"
                    alt="menu icon"
                    onClick={() => setMenu(false)}
                    className={styles["close-button"]}
                />
            </nav>
        </div>
    );
};

export default LateralMenu;
