import styles from "./Footer.module.scss";
import { Link } from "react-router-dom";

function Footer() {
    const year = new Date().getFullYear();

    return (
        <footer className={styles.footer}>
            <div className={styles.row1}>
                <div className={styles.col1}>
                    <h3>Directorio</h3>
                    <ul>
                        <li>
                            <Link to="/">Inicio</Link>
                        </li>
                        <li>
                            <Link to="/servicios">Servicios</Link>
                        </li>
                        <li>
                            <Link to="/contacto">Contacto</Link>
                        </li>
                        <li>
                            <Link to="/acerca">Acerca de</Link>
                        </li>
                    </ul>
                </div>
                <div className={styles.col2}>
                    <h3>Sólo Protesis Dental</h3>
                    <p>
                        Calle 61 # 586 x 74 y 76, Centro, Mérida, Yucatán,
                        México. C.P. 97000
                    </p>
                </div>
                <div className={styles.col3}>
                    <h3>Contáctanos</h3>
                    <ul>
                        <li>
                            <strong>Clínica:</strong> 999 928 25 28
                        </li>
                        <li>
                            {" "}
                            <strong>Emergencias:</strong> 999 200 83 28
                        </li>
                        <li>
                            {" "}
                            <strong>WhatsApp:</strong> 999 903 44 48
                        </li>
                        <li>
                            {" "}
                            <strong>Facebook:</strong> Sólo Prótesis Dental
                        </li>
                        <li>
                            {" "}
                            <strong>Email:</strong> soloprotesisdental@gmail.com
                        </li>
                    </ul>
                </div>
            </div>
            <div className={styles.row2}>
                <p>© {year} Solo Prótesis Dental</p>
            </div>
        </footer>
    );
}

export default Footer;
