import styles from "./Hero.module.scss";

const Hero = () => {
    return (
        <section className={styles.hero}>
            <div className={styles.description}>
                <h2 className={styles.title}>Consultorio y Laboratorio Dental</h2>
                <h1 className={styles.name}>Solo Prótesis Dental</h1>
                <p className={styles.slogan}>"Lo bueno no siempre es caro"</p>
            </div>
        </section>
    );
};

export default Hero;
