import { Outlet } from "react-router-dom";
import { MenuProvider } from "./contexts/MenuContext";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import WhatsApp from "./components/WhatsApp/WhatsApp";
import LateralMenu from "./components/LateralMenu/LateralMenu";
import styles from "./App.module.scss";
import ScrollToTop from "./hooks/ScrollToTop";

function App() {
    return (
        <div className={styles.container}>
            <ScrollToTop />
            <MenuProvider>
                <Header />
                <LateralMenu />
            </MenuProvider>
            <WhatsApp />
            <Outlet />
            <Footer />
        </div>
    );
}

export default App;
