import { ParallaxProvider, Parallax } from "react-scroll-parallax";
import styles from "./Teeth.module.scss";

const Teeth = props => {
    const teeth = [];

    for (let i = 0; i < props.count; i++) {
        let speed = Math.floor(Math.random() * 50) - 25;
        const top = Math.floor(Math.random() * props.height) - 200;
        const right = Math.floor(Math.random() * 100);

        teeth.push(
            <Parallax
                key={i}
                speed={speed}
                className={styles.teeth}
                style={{
                    top: `${top - 200}px`,
                    right: `${right}vw`,
                }}
            />
        );
    }

    return (
        <ParallaxProvider>
            <div className={styles.container}>{teeth}</div>
        </ParallaxProvider>
    );
};

export default Teeth;
