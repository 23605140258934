import styles from "./Schedule.module.scss";
import { Fade, JackInTheBox } from "react-awesome-reveal";
const Schedule = () => {
    return (
        <Fade triggerOnce>
            <section className={styles.schedule}>
                <h2 className="section-title">Horario</h2>
                <div className={styles.container}>
                    <JackInTheBox triggerOnce>
                    <img
                        src="images/icons/agenda.png"
                        alt="horario"
                        className={styles.design}
                    />
                    </JackInTheBox>
                    <div className={styles.table}>
                        <table>
                            <tbody>
                                <tr>
                                    <td>Lunes</td>
                                    <td>9 a.m. - 7 p.m.</td>
                                </tr>
                                <tr>
                                    <td>Martes</td>
                                    <td>9 a.m. - 7 p.m.</td>
                                </tr>
                                <tr>
                                    <td>Miércoles</td>
                                    <td>9 a.m. - 7 p.m.</td>
                                </tr>
                                <tr>
                                    <td>Jueves</td>
                                    <td>9 a.m. - 7 p.m.</td>
                                </tr>
                                <tr>
                                    <td>Viernes</td>
                                    <td>9 a.m. - 7 p.m.</td>
                                </tr>
                                <tr>
                                    <td>Sábado</td>
                                    <td>Cita previa</td>
                                </tr>
                                <tr>
                                    <td>Domingo</td>
                                    <td>Cerrado</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </Fade>
    );
};

export default Schedule;
