import { useLocation } from "react-router-dom";
import styles from "./WhatsApp.module.scss";

function WhatsApp() {
    const location = useLocation();

    const isHome = () => location.pathname === "/";

    return (
        <span
            className={styles["whatsapp-button-container"]}
            style={{ position: isHome() ? "sticky" : "fixed" }}
        >
            <a
                className={styles["whatsapp-button"]}
                href="https://wa.me/529999034448?text=Hola,%20quisiera%20agendar%20una%20cita"
                rel="noreferrer"
                target="_blank"
            >
                <img
                    src="images/icons/whatsapp.webp"
                    alt="whatsapp icon"
                    className={styles["whatsapp-icon"]}
                />
                WhatsApp
            </a>
        </span>
    );
}

export default WhatsApp;
