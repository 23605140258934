import React, { useEffect, useState } from "react";
import services from "../../data/services";
import styles from "./Services.module.scss";
import Teeth from "../../components/Teeth/Teeth";
import "animate.css";

function Services() {
    const [height, setHeight] = useState(0);

    useEffect(() => {
        setHeight(document.body.scrollHeight);
    }, []);

    return (
        <main
            className={`animate__animated animate__fadeIn ${styles.services}`}
        >
            <Teeth height={height} count={25} />
            <h1 className={styles.title}>Explora nuestros servicios</h1>
            <ul className={styles["services-container"]}>
                {services.map(service => (
                    <li key={service.title} className={styles.service}>
                        <img src={service.image} alt={service.title} />
                        <p>{service.title}</p>
                    </li>
                ))}
            </ul>
        </main>
    );
}

export default Services;
