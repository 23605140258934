import styles from "./Services.module.scss";
import { Link } from "react-router-dom";
import Service from "../Service/Service";
import services from "../../data/services";

function Services() {
    return (
        <section className={styles.services}>
            <h2 className="section-title">Nuestros Servicios</h2>
            <ul>
                {services.slice(0, 6).map(service => (
                    <Service
                        key={service.title}
                        title={service.title}
                        description={service.description}
                        image={service.image}
                    />
                ))}
            </ul>
            <Link to="/servicios" className="more-button">
                Más Servicios
            </Link>
        </section>
    );
}

export default Services;
