import styles from "./FindUs.module.scss";
import contactStyles from "../../views/Contact/Contact.module.scss";
import { Link } from "react-router-dom";

const FindUs = () => {
    return (
        <section className={styles["find-us"]}>
            <h2 className="section-title">Contacto</h2>
            <div className={styles.container}>
                <div className={contactStyles["contact-icon"]}>
                    <img src="images/icons/contact.png" alt="Contact icon" />
                </div>
                <h3 className={contactStyles.subtitle}>Teléfonos</h3>
                <ul className={contactStyles.numbers}>
                    <li>
                        Oficina ➡️ <a href="tel:9999282528">999 928 25 28</a>
                    </li>
                    <li>
                        WhatsApp ➡️{" "}
                        <a href="https://wa.me/529999034448?text=Hola,%20quisiera%20agendar%20una%20cita">
                            999 903 44 48
                        </a>
                    </li>
                    <li>
                        Emergencias ➡️{" "}
                        <a href="tel:9992008328">999 200 83 28</a>
                    </li>
                </ul>
                <a
                    className={contactStyles.address}
                    href="https://www.google.com/maps/place/SOLO+PROTESIS+DENTAL+CONSULTORIO+Y+LABORATORIO+DENTAL+INTEGRADO,+C.+61+586,+entre+74+y76,+Barrio+de+Santiago,+Centro,+97000+M%C3%A9rida,+Yuc./@20.9690082,-89.6331418,17z/data=!4m6!3m5!1s0x8f5673dc965b1a01:0x3090217dd39c0d42!8m2!3d20.9690082!4d-89.6331418!16s%2Fg%2F1ptxfhbys"
                >
                    Calle 61 # 586 x 74 y 76, Centro, Mérida, Yucatán, México.
                    C.P. 97000
                </a>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3725.5891486196065!2d-89.63533048508229!3d20.969008186030745!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f5673dc965b1a01%3A0x3090217dd39c0d42!2sSOLO%20PROTESIS%20DENTAL%20CONSULTORIO%20Y%20LABORATORIO%20DENTAL%20INTEGRADO!5e0!3m2!1sen!2smx!4v1681267326584!5m2!1sen!2smx"
                    referrerPolicy="no-referrer-when-downgrade"
                    title="Google Maps"
                ></iframe>
                <Link to="/contacto" className="more-button">
                    Contáctanos
                </Link>
            </div>
        </section>
    );
};

export default FindUs;
