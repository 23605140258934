import React from "react";
import ReactDOM from "react-dom/client";
import HttpsRedirect from "react-https-redirect";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.scss";
import App from "./App";
import Error from "./views/Error/Error";
import Home from "./views/Home/Home";
import About from "./views/About/About";
import Services from "./views/Services/Services";
import Contact from "./views/Contact/Contact";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <Error />,
        children: [
            { path: "/", element: <Home /> },
            { path: "/acerca", element: <About /> },
            { path: "/servicios", element: <Services /> },
            { path: "/contacto", element: <Contact /> },
        ],
    },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <HttpsRedirect>
        <React.StrictMode>
            <RouterProvider router={router} />
        </React.StrictMode>
    </HttpsRedirect>
);
