import { useState } from "react";
import styles from "./Facilities.module.scss";
import ImageGallery from "react-image-gallery";
import { useEffect } from "react";
import { Fade } from "react-awesome-reveal";

const images = [
    {
        original: "images/facilities/facilities-1.webp",
        thumbnail: "images/facilities/facilities-1-low.webp",
    },
    {
        original: "images/facilities/facilities-2.webp",
        thumbnail: "images/facilities/facilities-2-low.webp",
    },
    {
        original: "images/facilities/facilities-3.webp",
        thumbnail: "images/facilities/facilities-3-low.webp",
    },
    {
        original: "images/facilities/facilities-4.webp",
        thumbnail: "images/facilities/facilities-4-low.webp",
    },
    {
        original: "images/facilities/facilities-5.webp",
        thumbnail: "images/facilities/facilities-5-low.webp",
    },
];

const Facilities = () => {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <section className={styles.facilities}>
            <Fade triggerOnce={true}>
                <h2 className="section-title">Instalaciones</h2>
                <ImageGallery
                    items={images}
                    autoPlay={true}
                    showThumbnails={width >= 768 ? true : false}
                    showFullscreenButton={false}
                    showPlayButton={false}
                />
            </Fade>
        </section>
    );
};

export default Facilities;
