import styles from "./Service.module.scss";
import { Zoom } from "react-awesome-reveal";

function Service(props) {
    return (
        <div className={styles.service}>
            <Zoom triggerOnce={true}>
                <img
                    src={props.image}
                    alt={props.title}
                    className={styles.serviceImage}
                />
                <div className={styles.serviceInfo}>
                    <p className={styles.serviceTitle}>{props.title}</p>
                </div>
            </Zoom>
        </div>
    );
}

export default Service;
