import { useEffect, useState } from "react";
import { Fade } from "react-awesome-reveal";
import Hero from "../../components/Hero/Hero";
import About from "../../components/About/About";
import Services from "../../components/Services/Services";
import Facilities from "../../components/Facilities/Facilities";
import Doctor from "../../components/Doctor/Doctor";
import FindUs from "../../components/FindUs/FindUs";
import Teeth from "../../components/Teeth/Teeth";
import Schedule from "../../components/Schedule/Schedule";

function Home() {
    const [height, setHeight] = useState(0);

    useEffect(() => {
        setHeight(document.body.scrollHeight);
    }, []);

    return (
        <main className="animate__animated animate__fadeIn">
            <Teeth height={height} count={60} />
            <Hero />
            <Services />
            <Facilities />
            <Fade triggerOnce={true}>
                <FindUs />
            </Fade>
            <Schedule />
            <Doctor />
            <About />
        </main>
    );
}

export default Home;
