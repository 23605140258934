import { useEffect, useState } from "react";
import styles from "./About.module.scss";
import Teeth from "../../components/Teeth/Teeth";

function About() {
    const [height, setHeight] = useState(0);

    useEffect(() => {
        setHeight(document.body.scrollHeight);
    }, []);

    return (
        <main className={`animate__animated animate__fadeIn ${styles.about}`}>
            <Teeth height={height} count={25} />
            <h2 className={styles.title}>Acerca de nosotros</h2>
            <img
                src="/images/cover.webp"
                alt="Solo Prótesis Dental logo"
                className={styles.cover}
            />
            <p className={styles.description}>
                Somos un consultorio y laboratorio integrado que se formó en el
                año 2008, desde hace 30 años el{" "}
                <strong>Dr. Jose David Lara Montejo</strong> inicio con la
                empresa, por lo que contamos con una amplia experiencia. Asi
                surge <strong>Solo Prótesis Dental</strong> , al darnos cuenta
                de la necesidad de los pacientes los cuales no querían estar sin
                sus piezas dentales, porque afectaba su salud bucal y
                autoestima. Por lo que decidimos ahorrar el largo esperar del
                pasar de los trabajos a un laboratorio, asi nosotros asi
                nosotros hacemos el trabajo, dándonos la oportunidad de ofrecer
                trabajos el mismo día, como reparaciones inmediatas
            </p>
            <img
                src="/images/doctor.webp"
                alt="Dr. David Lara"
                className={styles.doctor}
            />
            <ul className={styles.values}>
                <li>
                    <h3>Misión</h3>
                    <p>
                        Nuestra misión es proporcionar un diagnóstico preciso y
                        brindar atención dental de alta calidad a todos los
                        pacientes que requieran tratamiento. Nos esforzamos por
                        ayudar a nuestros pacientes a recuperar su salud bucal
                        de la manera más adecuada posible.
                    </p>
                </li>
                <li>
                    <h3>Visión</h3>
                    <p>
                        En el consultorio y laboratorio dental, nos visualizamos
                        liderando la vanguardia en las nuevas técnicas
                        posicionándonos como uno de los mejores consultorios al
                        satisfacer de manera eficaz y eficiente las necesidades
                        de nuestros pacientes.
                    </p>
                </li>
                <li>
                    <h3>Objetivo</h3>
                    <p>
                        Nuestro objetivo principal es rehabilitar a nuestros
                        pacientes, permitiéndoles recuperar su sonrisa sin
                        comprometer la comodidad a la hora de hablar o
                        gesticular. Trabajamos para ofrecer soluciones dentales
                        que no sólo sean efectivas, sino que también brinden una
                        experiencia cómoda y satisfactoria para nuestros
                        pacientes.
                    </p>
                </li>
            </ul>
        </main>
    );
}

export default About;
